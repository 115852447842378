<template>
    <template v-if="item.editor && item.editor === 'datetime2'">
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.value" timepicker format="General Date Short Time" :placeholder="item.placeholder" v-if="!item.isRange"/>
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.rangeValue0" timepicker format="General Date Short Time" :placeholder="item.placeholder" :clearable="false" v-if="item.isRange"/>
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.rangeValue1" timepicker format="General Date Short Time" :placeholder="item.placeholder" :clearable="false" v-if="item.isRange"/>
    </template>
    <template v-else-if="item.editor && item.editor === 'datetime'"> 
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.value" date format="Short Date" :placeholder="item.placeHolder" v-if="!item.isRange"/>
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.rangeValue0" date format="Short Date" :placeholder="item.placeHolder" :clearable="false" v-if="item.isRange"/>
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.rangeValue1" date format="Short Date" :placeholder="item.placeHolder" :clearable="false" v-if="item.isRange"/>
    </template>
    <template v-else-if="item.editor && item.editor === 'time'"> 
        <DatePicker :class="[setEmptyClass(item), setFormControlClass()]" v-bind="$attrs" :disabled="isDisabled" v-model="item.value" timepickerOnly format="Short Time" :placeholder="item.placeHolder" v-if="!item.isRange"/>
    </template>
    <template v-else-if="item.editor && item.editor === 'textbox'">
        <OTextArea :type="item.valueType == 'number'?'number':'text'"  
            :class="[setEmptyClass(item),setFormControlClass()]"
            autoGrow 
            noResize
            :readonly="item.readonly" 
            :rows="item.rows ?? 1"
            rowHeight="10"
            style="margin-bottom:-6px;height:initial"
            :placeholder="item.placeHolder" v-model="item.value" ></OTextArea>
    </template>
    <template v-else-if="item.editor && item.editor === 'bit'">         
        <div class="form-check" v-if="addFormClasses">
            <input class="form-check-input" :disabled="isDisabled" :id="props.for" :class="setEmptyClass(item)" type="checkbox" v-model="item.value" :placeholder="item.placeHolder" />
        </div>
        <input v-else v-bind="$attrs" :id="props.for" :disabled="isDisabled" :class="setEmptyClass(item)" type="checkbox" v-model="item.value" :placeholder="item.placeHolder" />
    </template>
    <template v-else-if="item.isInputEditorItem && item.editor && item.editor === 'multiselect'">
        <OSelect :class="[setEmptyClass(item),setSelectClass()]" v-model="item.selectValue" style="max-height:30px" :placeholder="item.placeHolder" clearable
            :multiple="item?.multiple">
            <option v-for="value in item.lookupValues">{{value}}</option>
        </OSelect>      
    </template>
    <template v-else-if="item.editor && item.editor === 'multiselect'">
        <OSelect :class="[setEmptyClass(item),setSelectClass()]" v-model="item.value" style="max-height:30px" :placeholder="item.placeHolder" clearable>
            <option v-for="value in item.lookupValues">{{value}}</option>
        </OSelect>      
    </template>
    <template v-else-if="item.isLookup">
       <Lookups :addFormClasses="addFormClasses" :item="item" :config="item.config"/>
    </template>
    <template v-else-if="item.valueType == 'number' || item.valueType == 'bit'">
            {{item.value??item._value}}
    </template>
    <!-- <template v-else-if="item.valueType == 'datetime'"> -->
        <!-- <span> -->
            <!-- '{{formatDate(item.displayValue??item.value, "yyyy-MM-dd", false, false)}}' -->
        <!-- </span> -->
    <!-- </template> -->
    <template v-else-if="item.value">
        <span @click="() => {console.log(item.value)}">
            '{{item.displayValue??item.value}}'
        </span>
    </template>
</template>
<script setup>
    import DatePicker from 'o365.vue.components.DatePicker.vue';
    import Lookups from 'o365.vue.components.InputEditors.Lookups.vue';
    import OSelect from 'o365.vue.components.Select.vue';
    import { defineEmits, computed, useAttrs  } from 'vue';
    import { format as formatDate } from 'date-fns';

    const attrs = useAttrs();
    const emit = defineEmits(['change']);
    const props = defineProps({
        modelValue: null,
        item:Object,
        for:String,
        row:Object,
        addFormClasses:{
            type:Boolean,
            default:false
        },
        valueSetter: Function,
        valueGetter: Function    
    });

    const isDisabled = computed(()=>{
        return props.item.readonly || attrs.disabled;
    });

    const setEmptyClass = (item) =>{
        if(item.required && !item.value){
            return "empty"
        }
        if(!item.required && !item.value){
            return "empty"
        }
        return item.value?"":"empty";
    }
    
    const setSelectClass = () =>{
        if(props.addFormClasses){
            return 'form-select form-select-sm ';
        }
        return "";
    }

    const setFormControlClass = () =>{
        if(props.addFormClasses){
            return 'form-control form-control-sm ';
        }
        return "";
    } 
</script>
